import * as React from 'react';
import Grid from '@mui/material/Grid2';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Copyright from '../../Internals/components/Copyright';


import StatCard, {StatCardProps} from './StatCard';

const data: StatCardProps[] = [
    {
        title: 'Users',
        value: '14k',
        interval: 'Last 30 days',
        trend: 'up',
        data: [
            200, 24, 220, 260, 240, 380, 100, 240, 280, 240, 300, 340, 320, 360, 340, 380,
            360, 400, 380, 420, 400, 640, 340, 460, 440, 480, 460, 600, 880, 920,
        ],
    },
];

const modelPath = './3d_test_files/toon_cat_free/scene.gltf';

/**
 * MainGrid component renders a layout consisting of two sections: Overview and Details.
 * It uses a box to wrap the entire content, which includes typography headings and grids
 * for displaying stat cards and other detailed information. The layout adapts to various
 * screen sizes through responsive design.
 *
 * @return {JSX.Element} A React component that displays the main grid layout.
 */
export default function MainGrid() {
    return (
        <Box sx={{width: '100%', maxWidth: {sm: '100%', md: '1700px'}}}>
            {/* cards */}
            <Typography component="h2" variant="h6" sx={{mb: 2}}>
                Overview
            </Typography>
            <Grid
                container
                spacing={2}
                columns={12}
                sx={{mb: (theme) => theme.spacing(2)}}
            >
                {data.map((card, index) => (
                    <Grid key={index} size={{xs: 12, sm: 6, lg: 3}}>
                        <StatCard {...card} />
                    </Grid>
                ))}
                <Grid size={{xs: 12, sm: 6, lg: 3}}>
                </Grid>
            </Grid>
            <Typography component="h2" variant="h6" sx={{mb: 2}}>
                Details
            </Typography>
            <Grid container spacing={2} columns={12}>
                <Grid size={{md: 12, lg: 9}}>

                </Grid>
                <Grid size={{xs: 12, lg: 3}}>
                    <Stack gap={2} direction={{xs: 'column', sm: 'row', lg: 'column'}}>

                    </Stack>
                </Grid>
            </Grid>
            <Copyright sx={{my: 4}}/>
        </Box>
    );
}
