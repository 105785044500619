import * as React from 'react';
import {styled} from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import MuiToolbar from '@mui/material/Toolbar';
import {tabsClasses} from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import DashboardRoundedIcon from '@mui/icons-material/DashboardRounded';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import MenuButton from './MenuButton';
import SideMenu from './SideMenu';
import {DashboardMenuProps, getPrettyNameFromDashboardPage} from './DashboardHelpers';

const Toolbar = styled(MuiToolbar)({
    width: '100%',
    padding: '12px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    justifyContent: 'center',
    gap: '12px',
    flexShrink: 0,
    [`& ${tabsClasses.flexContainer}`]: {
        gap: '8px',
        p: '8px',
        pb: 0,
    },
});

/**
 * AppNavbar represents the navigation bar component for the dashboard in a React application.
 * It includes a mobile-friendly menu button and a drawer that can be toggled on and off.
 * This component is designed to only be displayed on smaller screens (mobile dimensions).
 *
 * @param {DashboardMenuProps} currentDashboardPage - The current page of the dashboard being viewed.
 * @param {function} setCurrentDashboardPage - A function to update the current dashboard page.
 *
 * @returns {React.FC} A functional component that renders the AppNavbar.
 */
const AppNavbar: React.FC<DashboardMenuProps> = ({currentDashboardPage, setCurrentDashboardPage}) => {
    const [open, setOpen] = React.useState(false);


    //this is the menu button when website is in mobile dimensions
    const toggleDrawer = (newOpen: boolean) => () => {
        setOpen(newOpen);

    };

    return (
        <AppBar
            position="fixed"
            sx={{
                display: {xs: 'auto', md: 'none'},
                boxShadow: 0,
                bgcolor: 'background.paper',
                backgroundImage: 'none',
                borderBottom: '1px solid',
                borderColor: 'divider',
                top: '56px',
            }}
        >
            <Toolbar variant="regular">
                <Stack
                    direction="row"
                    sx={{
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        flexGrow: 1,
                        width: '100%',
                    }}
                >
                    <Stack direction="row" spacing={1} sx={{justifyContent: 'center'}}>
                        <MenuButton aria-label="menu" onClick={toggleDrawer(true)}>
                            <MenuRoundedIcon/>
                        </MenuButton>
                        <Typography variant="h4" component="h1" sx={{color: 'text.primary'}}>
                            {getPrettyNameFromDashboardPage(currentDashboardPage)}
                        </Typography>
                    </Stack>


                </Stack>
            </Toolbar>
            {/* Mobile drawer */}
            <SwipeableDrawer
                anchor="left"
                open={open}
                onClose={toggleDrawer(false)}
                onOpen={toggleDrawer(true)}
            >
                <SideMenu currentDashboardPage={currentDashboardPage}
                          setCurrentDashboardPage={setCurrentDashboardPage}/>
            </SwipeableDrawer>
        </AppBar>
    );
}

export function CustomIcon() {
    return (
        <Box
            sx={{
                width: '1.5rem',
                height: '1.5rem',
                bgcolor: 'black',
                borderRadius: '999px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                alignSelf: 'center',
                backgroundImage:
                    'linear-gradient(135deg, hsl(210, 98%, 60%) 0%, hsl(210, 100%, 35%) 100%)',
                color: 'hsla(210, 100%, 95%, 0.9)',
                border: '1px solid',
                borderColor: 'hsl(210, 100%, 55%)',
                boxShadow: 'inset 0 2px 5px rgba(255, 255, 255, 0.3)',
            }}
        >

            <DashboardRoundedIcon color="inherit" sx={{fontSize: '1rem'}}/>
        </Box>
    );
}

export default AppNavbar;